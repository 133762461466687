import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
// import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const rollupFeature = config.features.rollup;

const Home = () => {
  return (
    <Box as="main">
      <Box
        w="100%"
        // eslint-disable-next-line max-len
        backgroundImage="https://docs.evire.io/~gitbook/image?url=https%3A%2F%2F2898917054-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FXlqf9zPXQnQoMQ145mMV%252Fuploads%252F8ShfZL6ifGAKWYUYMTgB%252Fevire%2520bg%25201.png%3Falt%3Dmedia%26token%3D72a738d9-19b1-45ba-af3a-1795ff6124c7&width=1248&dpr=2&quality=100&sign=67961dadb1905a6996fb30a1d1f3925fd399a86d3326089a8c837088462fbdb6"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom"
        borderRadius={{ base: 'md', lg: 'xl' }}
        px={{ base: 4, lg: 10 }}
        py={{ base: 3, lg: 20 }}
        minW={{ base: 'unset', lg: '900px' }}
        data-label="hero plate"
      >
        <Flex mb={{ base: 2, lg: 6 }} justifyContent="space-between" alignItems="center">
          <Heading
            as="h1"
            fontSize={{ base: '18px', lg: '40px' }}
            lineHeight={{ base: '24px', lg: '48px' }}
            fontWeight={ 600 }
            color="#101112"
          >
            {
              config.meta.seo.enhancedDataEnabled ?
                `${ config.chain.name } blockchain explorer` :
                `${ config.chain.name } explorer`
            }
          </Heading>
          <Box display={{ base: 'none', lg: 'flex' }}>
            { config.features.account.isEnabled && <ProfileMenuDesktop isHomePage/> }
            { config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage/> }
          </Box>
        </Flex>
        <SearchBar isHomepage/>
      </Box>
      <Stats/>
      <ChainIndicators/>
      { /* <AdBanner mt={ 6 } mx="auto" display="flex" justifyContent="center"/> */ }
      <Flex mt={ 6 } direction={{ base: 'column', lg: 'row' }} columnGap={ 12 } rowGap={ 6 }>
        { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? <LatestZkEvmL2Batches/> : <LatestBlocks/> }
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
